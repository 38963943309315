import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  Nav,
  Navbar,
  Dropdown,
  Image,
  Tab,
} from "react-bootstrap";
import ArrowRightIcon from "../app/assets/images/arrow-right.svg";
import logo from '../../src/app/assets/images/logo.png';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight, faArrowRight, faCaretDown, faCaretRight } from '@fortawesome/free-solid-svg-icons'

function HomeHeader() {

  const [isMobileView, setIsMobileView] = useState(false);

  const mobileView = () => {
    // Toggle based on window width
    if (window.innerWidth < 991) {
      setIsMobileView(true);
      console.log('hhhh')
    } else {
      setIsMobileView(false);
    }
  };

  useEffect(() => {
    // Set the initial state
    mobileView();

    // Add the event listener
    window.addEventListener('resize', mobileView);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener('resize', mobileView);
    };
  }, []);


  return (

    <>
      <Navbar collapseOnSelect expand="lg" className="headbg">
        <Container>
          <Navbar.Brand as={Link} to="/">
            <Image src={logo} className="logoimg" alt="brand" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ms-auto">

              {isMobileView ?
                <Dropdown
                  className="dropdownmenu"
                >
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    Products
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item as={Link} to="/software-development-services">Healthcare Management System</Dropdown.Item>
                    <Dropdown.Item href="#/action-2">Staff Management System</Dropdown.Item>
                    <Dropdown.Item href="#/action-3">Project Management System</Dropdown.Item>
                    <Dropdown.Item href="#/action-4">Customer Management System</Dropdown.Item>
                    <Dropdown.Item href="#/action-4">Human Resource Management System</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                :
                <Nav.Link >
                  <div className="customdropdown">
                    <Button className="dropbtn menubtn">Products
                      <FontAwesomeIcon className="ms-2" icon={faCaretDown} />
                    </Button>
                    <div className="dropdown-content">
                      <Container>
                        <div className="dropdownrow row">
                          <div className="dropdowncolumn column">
                            <div className="menuflex">
                              <Tab.Container defaultActiveKey="hms">
                                <div>
                                  <Nav variant="pills" className="flex-column">
                                    <Nav.Item>
                                      <Nav.Link eventKey="hms">Health Care Management System <FontAwesomeIcon className="ms-4" icon={faAngleRight} /></Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                      <Nav.Link eventKey="sms">Staff Management System <FontAwesomeIcon className="ms-4" icon={faAngleRight} /></Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                      <Nav.Link eventKey="pms">Project Management System <FontAwesomeIcon className="ms-4" icon={faAngleRight} /></Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                      <Nav.Link eventKey="crm">Customer Relationship Management <FontAwesomeIcon className="ms-4" icon={faAngleRight} /></Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                      <Nav.Link eventKey="hrm">Human Resource Management <FontAwesomeIcon className="ms-4" icon={faAngleRight} /></Nav.Link>
                                    </Nav.Item>
                                  </Nav>
                                </div>

                                <div>
                                  <Tab.Content>
                                    <Tab.Pane eventKey='hms'>
                                      <h2 className="secsubtitl">Healthcare Management System</h2>
                                      <div className="megamenuflex">
                                        <div className="megamenucont">
                                          <div>
                                            <div className="megamenufeat">
                                              <h2 className="megamenuh2">Frontend Development</h2>
                                              <p className="whatwedop">We build responsive, performance-driven interfaces using React.js, tailored for healthcare needs.</p>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="megamenucont">
                                          <div className="megamenufeat">
                                            <h2 className="megamenuh2">Backend Development</h2>
                                            <p className="whatwedop">Our mobile app development team crafts intuitive, feature-rich apps for iOS and Android, ensuring seamless user experiences.</p>
                                          </div>
                                        </div>
                                        <div className="megamenucont">
                                          <div className="megamenufeat">
                                            <h2 className="megamenuh2">UX Design</h2>
                                            <p className="whatwedop">User-focused designs simplify navigation for patients and providers.</p>
                                          </div>
                                        </div>
                                        <div className="megamenucont">
                                          <div className="megamenufeat">
                                            <h2 className="megamenuh2">DevOps</h2>
                                            <p className="whatwedop">Reliable CI/CD pipelines and cloud setups guarantee seamless deployments and scalability.</p>
                                          </div>
                                        </div>
                                        <div className="megamenucont">
                                          <div className="megamenufeat">
                                            <h2 className="megamenuh2">Testing</h2>
                                            <p className="whatwedop">Thorough testing ensures secure, bug-free, high-performing systems.</p>
                                          </div>
                                        </div>
                                        <div className="megamenucont">
                                          <div className="megamenufeat">
                                            <h2 className="megamenuh2">Mobile App Development</h2>
                                            <p className="whatwedop">User-friendly healthcare apps with React Native for patients and providers.</p>
                                          </div>
                                        </div>
                                      </div>
                                      <Link to='/healthcare-management-system' className="btn sitebtn borderbtn mt-2">
                                        Know More
                                      </Link>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey='sms'>
                                      <h2 className="secsubtitl">Staff Management System</h2>
                                      <div className="megamenuflex">
                                        <div className="megamenucont">
                                          <div>
                                            <div className="megamenufeat">
                                              <h2 className="megamenuh2">Frontend Development</h2>
                                              <p className="whatwedop">Dynamic dashboards built with React.js for easy staff tracking and updates.</p>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="megamenucont">
                                          <div className="megamenufeat">
                                            <h2 className="megamenuh2">Backend Development</h2>
                                            <p className="whatwedop">Efficient, secure systems handle employee data with Express.js and MongoDB.</p>
                                          </div>
                                        </div>
                                        <div className="megamenucont">
                                          <div className="megamenufeat">
                                            <h2 className="megamenuh2">Devops</h2>
                                            <p className="whatwedop">Automated pipelines and cloud hosting deliver consistent performance.</p>
                                          </div>
                                        </div>
                                        <div className="megamenucont">
                                          <div className="megamenufeat">
                                            <h2 className="megamenuh2">UX Design</h2>
                                            <p className="whatwedop">Intuitive designs enhance productivity for HR and employees alike.</p>
                                          </div>
                                        </div>
                                        <div className="megamenucont">
                                          <div className="megamenufeat">
                                            <h2 className="megamenuh2">Mobile App Development</h2>
                                            <p className="whatwedop">Cross-platform apps for managing staff on the go with React Native.</p>
                                          </div>
                                        </div>
                                        <div className="megamenucont">
                                          <div className="megamenufeat">
                                            <h2 className="megamenuh2">Software Testing</h2>
                                            <p className="whatwedop">Thorough testing ensures flawless operations and data integrity.</p>
                                          </div>
                                        </div>
                                      </div>
                                      <Link to='/staff-management-system' className="btn sitebtn borderbtn mt-2">
                                        Know More
                                      </Link>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey='pms'>
                                      <h2 className="secsubtitl">Project Management System</h2>
                                      <div className="megamenuflex">
                                        <div className="megamenucont">
                                          <div>
                                            <div className="megamenufeat">
                                              <h2 className="megamenuh2">Frontend Development</h2>
                                              <p className="whatwedop">Interactive, real-time dashboards crafted with React.js.</p>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="megamenucont">
                                          <div className="megamenufeat">
                                            <h2 className="megamenuh2">Backend Development</h2>
                                            <p className="whatwedop">Robust APIs manage tasks and files using Node.js and PostgreSQL.</p>
                                          </div>
                                        </div>
                                        <div className="megamenucont">
                                          <div className="megamenufeat">
                                            <h2 className="megamenuh2">UX Design</h2>
                                            <p className="whatwedop">User-friendly layouts simplify tracking and collaboration.</p>
                                          </div>
                                        </div>
                                        <div className="megamenucont">
                                          <div className="megamenufeat">
                                            <h2 className="megamenuh2">DevOps</h2>
                                            <p className="whatwedop">Scalable systems with Docker and Kubernetes ensure reliability.</p>
                                          </div>
                                        </div>
                                        <div className="megamenucont">
                                          <div className="megamenufeat">
                                            <h2 className="megamenuh2">Mobile App Development</h2>
                                            <p className="whatwedop">Mobile apps for on-the-go team collaboration and updates.</p>
                                          </div>
                                        </div>
                                        <div className="megamenucont">
                                          <div className="megamenufeat">
                                            <h2 className="megamenuh2">Software Testing</h2>
                                            <p className="whatwedop">Comprehensive testing ensures error-free project management.</p>
                                          </div>
                                        </div>
                                      </div>
                                      <Link to='/project-management-system' className="btn sitebtn borderbtn mt-2">
                                        Know More
                                      </Link>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey='crm'>
                                      <h2 className="secsubtitl">Customer Relationship Management System</h2>
                                      <div className="megamenuflex">
                                        <div className="megamenucont">
                                          <div>
                                            <div className="megamenufeat">
                                              <h2 className="megamenuh2">Frontend Development</h2>
                                              <p className="whatwedop">Interactive, real-time dashboards crafted with React.js.</p>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="megamenucont">
                                          <div className="megamenufeat">
                                            <h2 className="megamenuh2">Backend Development</h2>
                                            <p className="whatwedop">Robust APIs manage tasks and files using Node.js and PostgreSQL.</p>
                                          </div>
                                        </div>
                                        <div className="megamenucont">
                                          <div className="megamenufeat">
                                            <h2 className="megamenuh2">UX Design</h2>
                                            <p className="whatwedop">User-friendly layouts simplify tracking and collaboration.</p>
                                          </div>
                                        </div>
                                        <div className="megamenucont">
                                          <div className="megamenufeat">
                                            <h2 className="megamenuh2">DevOps</h2>
                                            <p className="whatwedop">Scalable systems with Docker and Kubernetes ensure reliability.</p>
                                          </div>
                                        </div>
                                        <div className="megamenucont">
                                          <div className="megamenufeat">
                                            <h2 className="megamenuh2">Mobile App Development</h2>
                                            <p className="whatwedop">Mobile apps for on-the-go team collaboration and updates.</p>
                                          </div>
                                        </div>
                                        <div className="megamenucont">
                                          <div className="megamenufeat">
                                            <h2 className="megamenuh2">Software Testing</h2>
                                            <p className="whatwedop">Comprehensive testing ensures error-free project management.</p>
                                          </div>
                                        </div>
                                      </div>
                                      <Link to='/customer-relationship-management-system' className="btn sitebtn borderbtn mt-2">
                                        Know More
                                      </Link>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey='hrm'>
                                      <h2 className="secsubtitl">Human Resource Management System</h2>
                                      <div className="megamenuflex">
                                        <div className="megamenucont">
                                          <div>
                                            <div className="megamenufeat">
                                              <h2 className="megamenuh2">Frontend Development</h2>
                                              <p className="whatwedop">Interactive, real-time dashboards crafted with React.js.</p>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="megamenucont">
                                          <div className="megamenufeat">
                                            <h2 className="megamenuh2">Backend Development</h2>
                                            <p className="whatwedop">Robust APIs manage tasks and files using Node.js and PostgreSQL.</p>
                                          </div>
                                        </div>
                                        <div className="megamenucont">
                                          <div className="megamenufeat">
                                            <h2 className="megamenuh2">UX Design</h2>
                                            <p className="whatwedop">User-friendly layouts simplify tracking and collaboration.</p>
                                          </div>
                                        </div>
                                        <div className="megamenucont">
                                          <div className="megamenufeat">
                                            <h2 className="megamenuh2">DevOps</h2>
                                            <p className="whatwedop">Scalable systems with Docker and Kubernetes ensure reliability.</p>
                                          </div>
                                        </div>
                                        <div className="megamenucont">
                                          <div className="megamenufeat">
                                            <h2 className="megamenuh2">Mobile App Development</h2>
                                            <p className="whatwedop">Mobile apps for on-the-go team collaboration and updates.</p>
                                          </div>
                                        </div>
                                        <div className="megamenucont">
                                          <div className="megamenufeat">
                                            <h2 className="megamenuh2">Software Testing</h2>
                                            <p className="whatwedop">Comprehensive testing ensures error-free project management.</p>
                                          </div>
                                        </div>
                                      </div>
                                      <Link to='/human-resource-management-system' className="btn sitebtn borderbtn mt-2">
                                        Know More
                                      </Link>
                                    </Tab.Pane>
                                  </Tab.Content>
                                </div>
                              </Tab.Container>
                            </div>
                          </div>
                        </div>
                      </Container>
                    </div>
                  </div>
                </Nav.Link>
              }

              {
                isMobileView ?
                  <Dropdown
                    className="dropdownmenu"
                  >
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                      Services
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item as={Link} to="/software-development-services">Web Development</Dropdown.Item>
                      <Dropdown.Item href="#/action-2">Mobile Development </Dropdown.Item>
                      <Dropdown.Item href="#/action-3">Blockchain Development </Dropdown.Item>
                      <Dropdown.Item href="#/action-4">AI & ML Development</Dropdown.Item>
                      <Dropdown.Item href="#/action-4">UI/UX Design</Dropdown.Item>
                      <Dropdown.Item href="#/action-4">Digital Marketing </Dropdown.Item>
                      <Dropdown.Item href="#/action-4">Branding </Dropdown.Item>
                      <Dropdown.Item href="#/action-4">QA Testing </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown> :
                  <Nav.Link>
                    <div className="customdropdown">
                      <Button className="dropbtn menubtn">Services
                        <FontAwesomeIcon className="ms-2" icon={faCaretDown} />
                      </Button>
                      <div className="dropdown-content">
                        <Container>
                          <div className="dropdownrow row">
                            <div className="dropdowncolumn column">
                              <div className="menuflex">
                                <Tab.Container defaultActiveKey="wd">
                                  <div>
                                    <Nav variant="pills" className="flex-column">
                                      <Nav.Item>
                                        <Nav.Link eventKey="wd">Web Development<FontAwesomeIcon className="ms-4" icon={faAngleRight} /></Nav.Link>
                                      </Nav.Item>
                                      <Nav.Item>
                                        <Nav.Link eventKey="md">Mobile Development <FontAwesomeIcon className="ms-4" icon={faAngleRight} /></Nav.Link>
                                      </Nav.Item>
                                      <Nav.Item>
                                        <Nav.Link eventKey="bd">Blockchain Development <FontAwesomeIcon className="ms-4" icon={faAngleRight} /></Nav.Link>
                                      </Nav.Item>
                                      <Nav.Item>
                                        <Nav.Link eventKey="ad">AI & ML Development <FontAwesomeIcon className="ms-4" icon={faAngleRight} /></Nav.Link>
                                      </Nav.Item>
                                      <Nav.Item>
                                        <Nav.Link eventKey="uiux">UI/UX Design <FontAwesomeIcon className="ms-4" icon={faAngleRight} /></Nav.Link>
                                      </Nav.Item>
                                      <Nav.Item>
                                        <Nav.Link eventKey="dm">Digital Marketing <FontAwesomeIcon className="ms-4" icon={faAngleRight} /></Nav.Link>
                                      </Nav.Item>
                                      <Nav.Item>
                                        <Nav.Link eventKey="qa">QA Testing <FontAwesomeIcon className="ms-4" icon={faAngleRight} /></Nav.Link>
                                      </Nav.Item>
                                    </Nav>
                                  </div>

                                  <div>
                                    <Tab.Content>
                                      <Tab.Pane eventKey='wd'>
                                        <h2 className="secsubtitl">Software Development</h2>
                                        <div className="megamenuflex">
                                          <div className="megamenucont">
                                            <div>
                                              <div className="megamenufeat">
                                                <h2 className="megamenuh2">Frontend Development</h2>
                                                <p className="whatwedop">We specialize in creating visually stunning and high-performance frontends using React.js and Next.js, ensuring a seamless user experience.</p>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="megamenucont">
                                            <div className="megamenufeat">
                                              <h2 className="megamenuh2">Backend Development</h2>
                                              <p className="whatwedop">Our secure and scalable backend systems, built with Node.js, MySQL, and MongoDB, efficiently handle data and ensure robust application performance.</p>
                                            </div>
                                          </div>
                                          <div className="megamenucont">
                                            <div className="megamenufeat">
                                              <h2 className="megamenuh2">DevOps</h2>
                                              <p className="whatwedop">We leverage Docker, AWS, and CI/CD pipelines to deliver scalable, reliable, and efficient infrastructure tailored to your needs.</p>
                                            </div>
                                          </div>
                                          <div className="megamenucont">
                                            <div className="megamenufeat">
                                              <h2 className="megamenuh2">UI/UX Design</h2>
                                              <p className="whatwedop">Our design team creates intuitive and engaging interfaces that enhance user experience and align with your business objectives.</p>
                                            </div>
                                          </div>
                                          <div className="megamenucont">
                                            <div className="megamenufeat">
                                              <h2 className="megamenuh2">Testing & Quality Assurance</h2>
                                              <p className="whatwedop">We use advanced testing methodologies and Java tools to ensure your software is bug-free, secure, and ready for deployment.</p>
                                            </div>
                                          </div>
                                        </div>
                                        <Link to='/software-development-services' className="btn sitebtn borderbtn mt-2">
                                          Know More
                                        </Link>
                                      </Tab.Pane>

                                      <Tab.Pane eventKey='md'>
                                        <h2 className="secsubtitl">Mobile App Development</h2>
                                        <div className="megamenuflex">
                                          <div className="megamenucont">
                                            <div>
                                              <div className="megamenufeat">
                                                <h2 className="megamenuh2">iOS App Development</h2>
                                                <p className="whatwedop">We create high-performance iOS applications, leveraging Swift and modern Apple frameworks to deliver seamless experiences.</p>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="megamenucont">
                                            <div className="megamenufeat">
                                              <h2 className="megamenuh2">Android App Development</h2>
                                              <p className="whatwedop">Our team builds robust Android apps using Kotlin and Java, optimized for performance and compatibility across devices.</p>
                                            </div>
                                          </div>
                                          <div className="megamenucont">
                                            <div className="megamenufeat">
                                              <h2 className="megamenuh2">Cross-Platform Apps</h2>
                                              <p className="whatwedop">Using React Native and Flutter, we deliver cross-platform apps with native-like performance and stunning designs.</p>
                                            </div>
                                          </div>
                                          <div className="megamenucont">
                                            <div className="megamenufeat">
                                              <h2 className="megamenuh2">UI/UX Design for Mobile</h2>
                                              <p className="whatwedop">Our design experts craft intuitive and engaging interfaces tailored for mobile users, ensuring exceptional user experiences.</p>
                                            </div>
                                          </div>
                                          <div className="megamenucont">
                                            <div className="megamenufeat">
                                              <h2 className="megamenuh2">App Backend Development</h2>
                                              <p className="whatwedop">We build scalable and secure backends for mobile apps, using Node.js, MongoDB, and cloud platforms like AWS and Firebase.</p>
                                            </div>
                                          </div>
                                          <div className="megamenucont">
                                            <div className="megamenufeat">
                                              <h2 className="megamenuh2">App Testing & Deployment</h2>
                                              <p className="whatwedop">We rigorously test apps for performance, security, and usability, ensuring a smooth launch on App Store and Play Store.</p>
                                            </div>
                                          </div>
                                        </div>
                                        <Link to='/mobile-app-development' className="btn sitebtn borderbtn mt-2">
                                          Know More
                                        </Link>
                                      </Tab.Pane>

                                      <Tab.Pane eventKey='bd'>
                                        <h2 className="secsubtitl">Blockchain Development</h2>
                                        <div className="megamenuflex">
                                          <div className="megamenucont">
                                            <div>
                                              <div className="megamenufeat">
                                                <h2 className="megamenuh2">Crypto Exchange Platforms</h2>
                                                <p className="whatwedop">We develop secure and scalable cryptocurrency exchange platforms with advanced trading features and seamless user experiences.</p>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="megamenucont">
                                            <div className="megamenufeat">
                                              <h2 className="megamenuh2">Decentralized Exchanges (DEX)</h2>
                                              <p className="whatwedop">Our expertise in blockchain enables us to create fully decentralized exchanges, empowering users with secure, peer-to-peer trading systems.</p>
                                            </div>
                                          </div>
                                          <div className="megamenucont">
                                            <div className="megamenufeat">
                                              <h2 className="megamenuh2">Smart Contract Development</h2>
                                              <p className="whatwedop">We build reliable and efficient smart contracts on Ethereum, Binance Smart Chain, and other blockchain networks to automate processes.</p>
                                            </div>
                                          </div>
                                          <div className="megamenucont">
                                            <div className="megamenufeat">
                                              <h2 className="megamenuh2">Initial Coin Offerings (ICOs)</h2>
                                              <p className="whatwedop">Launch your own ICO with our end-to-end development services, including token creation, wallet integration, and fundraising platforms.</p>
                                            </div>
                                          </div>
                                          <div className="megamenucont">
                                            <div className="megamenufeat">
                                              <h2 className="megamenuh2">NFT Marketplaces</h2>
                                              <p className="whatwedop">We design and develop NFT marketplaces where creators can mint, buy, and sell digital assets with a seamless user interface.</p>
                                            </div>
                                          </div>
                                          <div className="megamenucont">
                                            <div className="megamenufeat">
                                              <h2 className="megamenuh2">Blockchain Consulting</h2>
                                              <p className="whatwedop">Our experts provide tailored blockchain strategies to help you integrate decentralized technologies into your business effectively.</p>
                                            </div>
                                          </div>
                                        </div>
                                        <Link to='/blockchain-development' className="btn sitebtn borderbtn mt-2">
                                          Know More
                                        </Link>
                                      </Tab.Pane>

                                      <Tab.Pane eventKey='ad'>
                                        <h2 className="secsubtitl">AI & ML Development</h2>
                                        <div className="megamenuflex">
                                          <div className="megamenucont">
                                            <div>
                                              <div className="megamenufeat">
                                                <h2 className="megamenuh2">Predictive Analytics</h2>
                                                <p className="whatwedop">Leverage data-driven insights with our predictive analytics solutions, helping businesses forecast trends and make informed decisions.</p>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="megamenucont">
                                            <div className="megamenufeat">
                                              <h2 className="megamenuh2">Natural Language Processing (NLP)</h2>
                                              <p className="whatwedop">Our NLP solutions enable businesses to extract meaning from text, build chatbots, and automate customer interactions intelligently.</p>
                                            </div>
                                          </div>
                                          <div className="megamenucont">
                                            <div className="megamenufeat">
                                              <h2 className="megamenuh2">Computer Vision</h2>
                                              <p className="whatwedop">We build computer vision systems that process images and videos for applications like facial recognition, object detection, and quality assurance.</p>
                                            </div>
                                          </div>
                                          <div className="megamenucont">
                                            <div className="megamenufeat">
                                              <h2 className="megamenuh2">AI-Powered Recommendation Systems</h2>
                                              <p className="whatwedop">Enhance user engagement and sales with AI-driven recommendation engines tailored to your customers' preferences.</p>
                                            </div>
                                          </div>
                                          <div className="megamenucont">
                                            <div className="megamenufeat">
                                              <h2 className="megamenuh2">Robotic Process Automation (RPA)</h2>
                                              <p className="whatwedop">Automate repetitive tasks and workflows with our RPA solutions, improving operational efficiency and reducing costs.</p>
                                            </div>
                                          </div>
                                          <div className="megamenucont">
                                            <div className="megamenufeat">
                                              <h2 className="megamenuh2">AI Consulting & Strategy</h2>
                                              <p className="whatwedop">Our AI experts provide strategic guidance to help you integrate AI and machine learning into your business operations effectively.</p>
                                            </div>
                                          </div>
                                        </div>
                                        <Link to='/ai-ml-development-services' className="btn sitebtn borderbtn mt-2">
                                          Know More
                                        </Link>
                                      </Tab.Pane>

                                      <Tab.Pane eventKey='uiux'>
                                        <h2 className="secsubtitl">UI/UX Design</h2>
                                        <div className="megamenuflex">
                                          <div className="megamenucont">
                                            <div>
                                              <div className="megamenufeat">
                                                <h2 className="megamenuh2">User Research</h2>
                                                <p className="whatwedop">We conduct in-depth user research to understand your audience's needs, behaviors, and pain points, creating a foundation for intuitive design.</p>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="megamenucont">
                                            <div className="megamenufeat">
                                              <h2 className="megamenuh2">Wireframing & Prototyping</h2>
                                              <p className="whatwedop">Our team designs detailed wireframes and interactive prototypes to visualize the user journey and gather early feedback for refinements.</p>
                                            </div>
                                          </div>
                                          <div className="megamenucont">
                                            <div className="megamenufeat">
                                              <h2 className="megamenuh2">Visual Design</h2>
                                              <p className="whatwedop">We create stunning visuals that align with your brand identity, ensuring your digital products look and feel exceptional to users.</p>
                                            </div>
                                          </div>
                                          <div className="megamenucont">
                                            <div className="megamenufeat">
                                              <h2 className="megamenuh2">Interaction Design</h2>
                                              <p className="whatwedop">From smooth transitions to engaging animations, we craft interactions that enhance usability and create delightful user experiences.</p>
                                            </div>
                                          </div>
                                          <div className="megamenucont">
                                            <div className="megamenufeat">
                                              <h2 className="megamenuh2">Usability Testing</h2>
                                              <p className="whatwedop">We conduct rigorous usability testing to identify pain points and optimize your design for maximum user satisfaction and efficiency.</p>
                                            </div>
                                          </div>
                                          <div className="megamenucont">
                                            <div className="megamenufeat">
                                              <h2 className="megamenuh2">Design Systems & Guidelines</h2>
                                              <p className="whatwedop">We build comprehensive design systems and guidelines to ensure consistency across all digital products and teams.</p>
                                            </div>
                                          </div>
                                        </div>
                                        <Link to='/ui-ux-design-services' className="btn sitebtn borderbtn mt-2">
                                          Know More
                                        </Link>
                                      </Tab.Pane>

                                      <Tab.Pane eventKey='dm'>
                                        <h2 className="secsubtitl">Digital Marketing Service</h2>
                                        <div className="megamenuflex">
                                          <div className="megamenucont">
                                            <div>
                                              <div className="megamenufeat">
                                                <h2 className="megamenuh2">SEO Optimization</h2>
                                                <p className="whatwedop">We optimize your website for search engines to improve visibility and drive organic traffic, enhancing your online presence.</p>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="megamenucont">
                                            <div className="megamenufeat">
                                              <h2 className="megamenuh2">Content Marketing</h2>
                                              <p className="whatwedop">Our team creates engaging and valuable content that resonates with your audience, driving conversions and building brand loyalty.</p>
                                            </div>
                                          </div>
                                          <div className="megamenucont">
                                            <div className="megamenufeat">
                                              <h2 className="megamenuh2">Social Media Strategy</h2>
                                              <p className="whatwedop">We develop and implement effective social media strategies to boost engagement, increase brand awareness, and drive traffic to your website.</p>
                                            </div>
                                          </div>
                                          <div className="megamenucont">
                                            <div className="megamenufeat">
                                              <h2 className="megamenuh2">PPC Advertising</h2>
                                              <p className="whatwedop">We run targeted Pay-Per-Click campaigns that drive immediate traffic to your website, maximizing ROI with strategic ad placements.</p>
                                            </div>
                                          </div>
                                          <div className="megamenucont">
                                            <div className="megamenufeat">
                                              <h2 className="megamenuh2">Email Marketing</h2>
                                              <p className="whatwedop">We design personalized email marketing campaigns that nurture leads and keep your audience engaged, improving customer retention.</p>
                                            </div>
                                          </div>
                                          <div className="megamenucont">
                                            <div className="megamenufeat">
                                              <h2 className="megamenuh2">Analytics & Reporting</h2>
                                              <p className="whatwedop">We provide detailed analytics and performance reports to measure the success of your digital marketing efforts and optimize strategies.</p>
                                            </div>
                                          </div>
                                        </div>
                                        <Link to='/digital-marketing' className="btn sitebtn borderbtn mt-2">
                                          Know More
                                        </Link>
                                      </Tab.Pane>

                                      <Tab.Pane eventKey='qa'>
                                        <h2 className="secsubtitl">QA Testing Service</h2>
                                        <div className="megamenuflex">
                                          <div className="megamenucont">
                                            <div>
                                              <div className="megamenufeat">
                                                <h2 className="megamenuh2">Functional Testing</h2>
                                                <p className="whatwedop">We ensure that all features of your application function as expected by performing thorough functional testing on all use cases.</p>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="megamenucont">
                                            <div className="megamenufeat">
                                              <h2 className="megamenuh2">Performance Testing</h2>
                                              <p className="whatwedop">We test your application under different load conditions to ensure it performs well under stress, ensuring optimal performance for users.</p>
                                            </div>
                                          </div>
                                          <div className="megamenucont">
                                            <div className="megamenufeat">
                                              <h2 className="megamenuh2">Security Testing</h2>
                                              <p className="whatwedop">We conduct rigorous security tests to identify vulnerabilities and safeguard your application from potential threats and breaches.</p>
                                            </div>
                                          </div>
                                          <div className="megamenucont">
                                            <div className="megamenufeat">
                                              <h2 className="megamenuh2">Usability Testing</h2>
                                              <p className="whatwedop">We test the usability of your application to ensure that it's user-friendly, intuitive, and offers a positive experience to your end-users.</p>
                                            </div>
                                          </div>
                                          <div className="megamenucont">
                                            <div className="megamenufeat">
                                              <h2 className="megamenuh2">Regression Testing</h2>
                                              <p className="whatwedop">We perform regression testing to ensure that new code changes do not negatively affect existing functionality, keeping your app stable over time.</p>
                                            </div>
                                          </div>
                                          <div className="megamenucont">
                                            <div className="megamenufeat">
                                              <h2 className="megamenuh2">Automation Testing</h2>
                                              <p className="whatwedop">We implement automation tests to reduce manual effort, increase efficiency, and ensure faster releases with consistent test coverage.</p>
                                            </div>
                                          </div>
                                        </div>
                                        <Link to='/qa-testing' className="btn sitebtn borderbtn mt-2">
                                          Know More
                                        </Link>
                                      </Tab.Pane>


                                    </Tab.Content>
                                  </div>
                                </Tab.Container>
                              </div>
                            </div>
                          </div>
                        </Container>
                      </div>
                    </div>
                  </Nav.Link>
              }

              {
                isMobileView ?
                  <Dropdown
                    className="dropdownmenu"
                  >
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                      Resouces
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item as={Link} to="/software-development-services">Customer Stories</Dropdown.Item>
                      <Dropdown.Item href="#/action-2">Academy </Dropdown.Item>
                      <Dropdown.Item href="#/action-3">Awards </Dropdown.Item>
                      <Dropdown.Item href="#/action-4">Work Environment</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown> :
                  <Nav.Link>
                    <div className="customdropdown">
                      <Button className="dropbtn menubtn">Resources
                        <FontAwesomeIcon className="ms-2" icon={faCaretDown} />
                      </Button>
                      <div className="dropdown-content">
                        <Container>
                          <div className="dropdownrow row">
                            <div className="dropdowncolumn column">
                              <div className="menuflex">
                                <Tab.Container defaultActiveKey="cs">
                                  <div>
                                    <Nav variant="pills" className="flex-column">
                                      <Nav.Item>
                                        <Nav.Link eventKey="cs">customer Stories<FontAwesomeIcon className="ms-4" icon={faAngleRight} /></Nav.Link>
                                      </Nav.Item>
                                      {/* <Nav.Item>
                                        <Nav.Link eventKey="ac">Academy <FontAwesomeIcon className="ms-4" icon={faAngleRight} /></Nav.Link>
                                      </Nav.Item>
                                      <Nav.Item>
                                        <Nav.Link eventKey="aw">Awards <FontAwesomeIcon className="ms-4" icon={faAngleRight} /></Nav.Link>
                                      </Nav.Item> */}
                                      <Nav.Item>
                                        <Nav.Link eventKey="we">Work Environment <FontAwesomeIcon className="ms-4" icon={faAngleRight} /></Nav.Link>
                                      </Nav.Item>
                                    </Nav>
                                  </div>

                                  <div>
                                    <Tab.Content>
                                      <Tab.Pane eventKey='cs'>
                                        <h2 className="secsubtitl">Customer Stories</h2>
                                        <div className="megamenuflex">
                                          <div className="megamenucont">
                                            <div>
                                              <div className="megamenufeat">
                                                <h2 className="megamenuh2">Web Development</h2>
                                                <p className="whatwedop">Our clients have seen transformative results with custom-built websites and web applications that cater to their specific business needs and drive measurable growth.</p>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="megamenucont">
                                            <div className="megamenufeat">
                                              <h2 className="megamenuh2">Mobile App Development</h2>
                                              <p className="whatwedop">Our customers have experienced seamless, feature-rich mobile app solutions that elevate their businesses on both iOS and Android platforms.</p>
                                            </div>
                                          </div>
                                          <div className="megamenucont">
                                            <div className="megamenufeat">
                                              <h2 className="megamenuh2">Visual Graphic Design</h2>
                                              <p className="whatwedop">Our design team helped clients build a strong visual identity, creating memorable brand experiences that connect with their target audience through stunning graphics.</p>
                                            </div>
                                          </div>
                                          <div className="megamenucont">
                                            <div className="megamenufeat">
                                              <h2 className="megamenuh2">Software Development</h2>
                                              <p className="whatwedop">Our software development services have empowered businesses by creating robust digital platforms tailored to their unique requirements, ensuring long-term success and customer satisfaction.</p>
                                            </div>
                                          </div>
                                          <div className="megamenucont">
                                            <div className="megamenufeat">
                                              <h2 className="megamenuh2">Blockchain Development</h2>
                                              <p className="whatwedop">We helped clients innovate and gain a competitive edge by implementing blockchain technology, creating secure, transparent, and decentralized solutions for their businesses.</p>
                                            </div>
                                          </div>
                                          <div className="megamenucont">
                                            <div className="megamenufeat">
                                              <h2 className="megamenuh2">Software Testing</h2>
                                              <p className="whatwedop">Our rigorous software testing processes have ensured that our clients' products are reliable, secure, and meet the highest performance standards, giving them peace of mind and enhanced user experience.</p>
                                            </div>
                                          </div>
                                        </div>
                                        <Link to='/customer-stories' className="btn sitebtn borderbtn mt-2">
                                          Know More
                                        </Link>
                                      </Tab.Pane>

                                      {/* <Tab.Pane eventKey='ac'>
                                        <h2 className="secsubtitl">Staff Management System</h2>
                                        <div className="megamenuflex">
                                          <div className="megamenucont">
                                            <div>
                                              <div className="megamenufeat">
                                                <h2 className="megamenuh2">Web Development</h2>
                                                <p className="whatwedop">From sleek websites to robust web applications, we build digital platforms that engage users and drive results.</p>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="megamenucont">
                                            <div className="megamenufeat">
                                              <h2 className="megamenuh2">Mobile App Development</h2>
                                              <p className="whatwedop">Our mobile app development team crafts intuitive, feature-rich apps for iOS and Android, ensuring seamless user experiences.</p>
                                            </div>
                                          </div>
                                          <div className="megamenucont">
                                            <div className="megamenufeat">
                                              <h2 className="megamenuh2">Visual Graphic Design</h2>
                                              <p className="whatwedop">Our creative graphic designers bring your brand to life with stunning visuals that resonate with your audience.</p>
                                            </div>
                                          </div>
                                          <div className="megamenucont">
                                            <div className="megamenufeat">
                                              <h2 className="megamenuh2">Software Development</h2>
                                              <p className="whatwedop">From sleek websites to robust web applications, we build digital platforms that engage users and drive results.</p>
                                            </div>
                                          </div>
                                          <div className="megamenucont">
                                            <div className="megamenufeat">
                                              <h2 className="megamenuh2">Blockchain Development</h2>
                                              <p className="whatwedop">Revolutionize with blockchain. We develop decentralized applications and systems that enhance security and transparency.</p>
                                            </div>
                                          </div>
                                          <div className="megamenucont">
                                            <div className="megamenufeat">
                                              <h2 className="megamenuh2">Software Testing</h2>
                                              <p className="whatwedop">Quality assurance for flawless performance. Our rigorous testing processes ensure that your software is reliable, secure, and bug-free.</p>
                                            </div>
                                          </div>
                                        </div>
                                        <Link to='/contact-us' className="btn sitebtn mt-2">
                                          Talk to our expert
                                        </Link>
                                      </Tab.Pane>
                                      <Tab.Pane eventKey='aw'>
                                        <h2 className="secsubtitl">Project Management System</h2>
                                        <div className="megamenuflex">
                                          <div className="megamenucont">
                                            <div>
                                              <div className="megamenufeat">
                                                <h2 className="megamenuh2">Web Development</h2>
                                                <p className="whatwedop">From sleek websites to robust web applications, we build digital platforms that engage users and drive results.</p>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="megamenucont">
                                            <div className="megamenufeat">
                                              <h2 className="megamenuh2">Mobile App Development</h2>
                                              <p className="whatwedop">Our mobile app development team crafts intuitive, feature-rich apps for iOS and Android, ensuring seamless user experiences.</p>
                                            </div>
                                          </div>
                                          <div className="megamenucont">
                                            <div className="megamenufeat">
                                              <h2 className="megamenuh2">Visual Graphic Design</h2>
                                              <p className="whatwedop">Our creative graphic designers bring your brand to life with stunning visuals that resonate with your audience.</p>
                                            </div>
                                          </div>
                                          <div className="megamenucont">
                                            <div className="megamenufeat">
                                              <h2 className="megamenuh2">Software Development</h2>
                                              <p className="whatwedop">From sleek websites to robust web applications, we build digital platforms that engage users and drive results.</p>
                                            </div>
                                          </div>
                                          <div className="megamenucont">
                                            <div className="megamenufeat">
                                              <h2 className="megamenuh2">Blockchain Development</h2>
                                              <p className="whatwedop">Revolutionize with blockchain. We develop decentralized applications and systems that enhance security and transparency.</p>
                                            </div>
                                          </div>
                                          <div className="megamenucont">
                                            <div className="megamenufeat">
                                              <h2 className="megamenuh2">Software Testing</h2>
                                              <p className="whatwedop">Quality assurance for flawless performance. Our rigorous testing processes ensure that your software is reliable, secure, and bug-free.</p>
                                            </div>
                                          </div>
                                        </div>
                                        <Link to='/contact-us' className="btn sitebtn mt-2">
                                          Talk to our expert
                                        </Link>
                                      </Tab.Pane> */}

                                      <Tab.Pane eventKey='we'>
                                        <h2 className="secsubtitl">Work Environment</h2>
                                        <div className="megamenuflex">
                                          <div className="megamenucont">
                                            <div>
                                              <div className="megamenufeat">
                                                <h2 className="megamenuh2">Collaboration</h2>
                                                <p className="whatwedop">We foster a collaborative environment where team members work together seamlessly to bring innovative ideas to life and solve complex problems.</p>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="megamenucont">
                                            <div className="megamenufeat">
                                              <h2 className="megamenuh2">Innovation</h2>
                                              <p className="whatwedop">Our team is driven by innovation, constantly exploring new technologies and strategies to improve processes, products, and services within a dynamic and creative workspace.</p>
                                            </div>
                                          </div>
                                          <div className="megamenucont">
                                            <div className="megamenufeat">
                                              <h2 className="megamenuh2">Growth Opportunities</h2>
                                              <p className="whatwedop">We offer growth opportunities, encouraging personal development and skill advancement through mentorship, training, and hands-on experience.</p>
                                            </div>
                                          </div>
                                          <div className="megamenucont">
                                            <div className="megamenufeat">
                                              <h2 className="megamenuh2">Team Spirit</h2>
                                              <p className="whatwedop">Our strong team spirit fuels a positive and inclusive work environment, where everyone’s contributions are valued and respected.</p>
                                            </div>
                                          </div>
                                          <div className="megamenucont">
                                            <div className="megamenufeat">
                                              <h2 className="megamenuh2">Flexibility</h2>
                                              <p className="whatwedop">We believe in work-life balance, offering flexible working hours and remote work options to create a supportive and adaptable environment for our team members.</p>
                                            </div>
                                          </div>
                                          <div className="megamenucont">
                                            <div className="megamenufeat">
                                              <h2 className="megamenuh2">Well-being</h2>
                                              <p className="whatwedop">Employee well-being is a top priority. We support mental, physical, and emotional health through various wellness programs and a balanced work culture.</p>
                                            </div>
                                          </div>
                                        </div>
                                        <Link to='/work-environment' className="btn sitebtn borderbtn mt-2">
                                          Know More
                                        </Link>
                                      </Tab.Pane>

                                    </Tab.Content>
                                  </div>
                                </Tab.Container>
                              </div>
                            </div>
                          </div>
                        </Container>
                      </div>
                    </div>
                  </Nav.Link>
              }

              <Nav.Link href="#">Portfolio</Nav.Link>
              <Nav.Link target="_blank" href="https://1techspot.com/blog">Blog</Nav.Link>
              <Nav.Link href="#">About Us</Nav.Link>
              <Nav.Link as={Link} to="/contact-us" className="contbtnlink headerbtn">
                <Button variant="outline-secondary" id="button-addon2">Contact Us <span className="ms-2 cntbtnbg"><Image src={ArrowRightIcon} /></span>
                </Button>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>



      {/* <div className="customnavbar">
        <Container>
          <div className="customnav">

            <Link to='/'>
              <Image src={logo} className="logoimg" alt="brand" />
            </Link>
            <div className="d-flex">
              <div className="customdropdown">
                <Button className="dropbtn menubtn">Products
                  <FontAwesomeIcon className="ms-2" icon={faCaretDown} />
                </Button>
                <div className="dropdown-content">
                  <Container>
                    <div className="dropdownrow row">
                      <div className="dropdowncolumn column">
                        <div className="menuflex">
                          <Tab.Container defaultActiveKey="hms">
                            <div>
                              <Nav variant="pills" className="flex-column">
                                <Nav.Item>
                                  <Nav.Link eventKey="hms">Health Care Management System <FontAwesomeIcon className="ms-4" icon={faAngleRight} /></Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                  <Nav.Link eventKey="sms">Staff Management System <FontAwesomeIcon className="ms-4" icon={faAngleRight} /></Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                  <Nav.Link eventKey="pms">Project Management System <FontAwesomeIcon className="ms-4" icon={faAngleRight} /></Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                  <Nav.Link eventKey="crm">Customer Relationship Management <FontAwesomeIcon className="ms-4" icon={faAngleRight} /></Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                  <Nav.Link eventKey="hrm">Human Resource Management <FontAwesomeIcon className="ms-4" icon={faAngleRight} /></Nav.Link>
                                </Nav.Item>
                              </Nav>
                            </div>

                            <div>
                              <Tab.Content>
                                <Tab.Pane eventKey='hms'>
                                  <h2 className="secsubtitl">Healthcare Management System</h2>
                                  <div className="megamenuflex">
                                    <div className="megamenucont">
                                      <div>
                                        <div className="megamenufeat">
                                          <h2 className="megamenuh2">Web Development</h2>
                                          <p className="whatwedop">From sleek websites to robust web applications, we build digital platforms that engage users and drive results.</p>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="megamenucont">
                                      <div className="megamenufeat">
                                        <h2 className="megamenuh2">Mobile App Development</h2>
                                        <p className="whatwedop">Our mobile app development team crafts intuitive, feature-rich apps for iOS and Android, ensuring seamless user experiences.</p>
                                      </div>
                                    </div>
                                    <div className="megamenucont">
                                      <div className="megamenufeat">
                                        <h2 className="megamenuh2">Visual Graphic Design</h2>
                                        <p className="whatwedop">Our creative graphic designers bring your brand to life with stunning visuals that resonate with your audience.</p>
                                      </div>
                                    </div>
                                    <div className="megamenucont">
                                      <div className="megamenufeat">
                                        <h2 className="megamenuh2">Software Development</h2>
                                        <p className="whatwedop">From sleek websites to robust web applications, we build digital platforms that engage users and drive results.</p>
                                      </div>
                                    </div>
                                    <div className="megamenucont">
                                      <div className="megamenufeat">
                                        <h2 className="megamenuh2">Blockchain Development</h2>
                                        <p className="whatwedop">Revolutionize with blockchain. We develop decentralized applications and systems that enhance security and transparency.</p>
                                      </div>
                                    </div>
                                    <div className="megamenucont">
                                      <div className="megamenufeat">
                                        <h2 className="megamenuh2">Software Testing</h2>
                                        <p className="whatwedop">Quality assurance for flawless performance. Our rigorous testing processes ensure that your software is reliable, secure, and bug-free.</p>
                                      </div>
                                    </div>
                                  </div>
                                  <Link to='/contact-us' className="btn sitebtn mt-2">
                                    Talk to our expert
                                  </Link>
                                </Tab.Pane>
                                <Tab.Pane eventKey='sms'>
                                  <h2 className="secsubtitl">Staff Management System</h2>
                                  <div className="megamenuflex">
                                    <div className="megamenucont">
                                      <div>
                                        <div className="megamenufeat">
                                          <h2 className="megamenuh2">Web Development</h2>
                                          <p className="whatwedop">From sleek websites to robust web applications, we build digital platforms that engage users and drive results.</p>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="megamenucont">
                                      <div className="megamenufeat">
                                        <h2 className="megamenuh2">Mobile App Development</h2>
                                        <p className="whatwedop">Our mobile app development team crafts intuitive, feature-rich apps for iOS and Android, ensuring seamless user experiences.</p>
                                      </div>
                                    </div>
                                    <div className="megamenucont">
                                      <div className="megamenufeat">
                                        <h2 className="megamenuh2">Visual Graphic Design</h2>
                                        <p className="whatwedop">Our creative graphic designers bring your brand to life with stunning visuals that resonate with your audience.</p>
                                      </div>
                                    </div>
                                    <div className="megamenucont">
                                      <div className="megamenufeat">
                                        <h2 className="megamenuh2">Software Development</h2>
                                        <p className="whatwedop">From sleek websites to robust web applications, we build digital platforms that engage users and drive results.</p>
                                      </div>
                                    </div>
                                    <div className="megamenucont">
                                      <div className="megamenufeat">
                                        <h2 className="megamenuh2">Blockchain Development</h2>
                                        <p className="whatwedop">Revolutionize with blockchain. We develop decentralized applications and systems that enhance security and transparency.</p>
                                      </div>
                                    </div>
                                    <div className="megamenucont">
                                      <div className="megamenufeat">
                                        <h2 className="megamenuh2">Software Testing</h2>
                                        <p className="whatwedop">Quality assurance for flawless performance. Our rigorous testing processes ensure that your software is reliable, secure, and bug-free.</p>
                                      </div>
                                    </div>
                                  </div>
                                  <Link to='/contact-us' className="btn sitebtn mt-2">
                                    Talk to our expert
                                  </Link>
                                </Tab.Pane>
                                <Tab.Pane eventKey='pms'>
                                  <h2 className="secsubtitl">Project Management System</h2>
                                  <div className="megamenuflex">
                                    <div className="megamenucont">
                                      <div>
                                        <div className="megamenufeat">
                                          <h2 className="megamenuh2">Web Development</h2>
                                          <p className="whatwedop">From sleek websites to robust web applications, we build digital platforms that engage users and drive results.</p>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="megamenucont">
                                      <div className="megamenufeat">
                                        <h2 className="megamenuh2">Mobile App Development</h2>
                                        <p className="whatwedop">Our mobile app development team crafts intuitive, feature-rich apps for iOS and Android, ensuring seamless user experiences.</p>
                                      </div>
                                    </div>
                                    <div className="megamenucont">
                                      <div className="megamenufeat">
                                        <h2 className="megamenuh2">Visual Graphic Design</h2>
                                        <p className="whatwedop">Our creative graphic designers bring your brand to life with stunning visuals that resonate with your audience.</p>
                                      </div>
                                    </div>
                                    <div className="megamenucont">
                                      <div className="megamenufeat">
                                        <h2 className="megamenuh2">Software Development</h2>
                                        <p className="whatwedop">From sleek websites to robust web applications, we build digital platforms that engage users and drive results.</p>
                                      </div>
                                    </div>
                                    <div className="megamenucont">
                                      <div className="megamenufeat">
                                        <h2 className="megamenuh2">Blockchain Development</h2>
                                        <p className="whatwedop">Revolutionize with blockchain. We develop decentralized applications and systems that enhance security and transparency.</p>
                                      </div>
                                    </div>
                                    <div className="megamenucont">
                                      <div className="megamenufeat">
                                        <h2 className="megamenuh2">Software Testing</h2>
                                        <p className="whatwedop">Quality assurance for flawless performance. Our rigorous testing processes ensure that your software is reliable, secure, and bug-free.</p>
                                      </div>
                                    </div>
                                  </div>
                                  <Link to='/contact-us' className="btn sitebtn mt-2">
                                    Talk to our expert
                                  </Link>
                                </Tab.Pane>
                              </Tab.Content>
                            </div>
                          </Tab.Container>
                        </div>
                      </div>
                    </div>
                  </Container>
                </div>
              </div>
              <div className="customdropdown">
                <Button className="dropbtn menubtn">Services
                  <FontAwesomeIcon className="ms-2" icon={faCaretDown} />
                </Button>
                <div className="dropdown-content">
                  <Container>
                    <div className="dropdownrow row">
                      <div className="dropdowncolumn column">
                        <div className="menuflex">
                          <Tab.Container defaultActiveKey="wd">
                            <div>
                              <Nav variant="pills" className="flex-column">
                                <Nav.Item>
                                  <Nav.Link eventKey="wd">Web Development<FontAwesomeIcon className="ms-4" icon={faAngleRight} /></Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                  <Nav.Link eventKey="md">Mobile Development <FontAwesomeIcon className="ms-4" icon={faAngleRight} /></Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                  <Nav.Link eventKey="bd">Blockchain Development <FontAwesomeIcon className="ms-4" icon={faAngleRight} /></Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                  <Nav.Link eventKey="ad">AI & ML Development <FontAwesomeIcon className="ms-4" icon={faAngleRight} /></Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                  <Nav.Link eventKey="uiux">UI/UX Design <FontAwesomeIcon className="ms-4" icon={faAngleRight} /></Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                  <Nav.Link eventKey="dm">Digital Marketing <FontAwesomeIcon className="ms-4" icon={faAngleRight} /></Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                  <Nav.Link eventKey="br">Branding <FontAwesomeIcon className="ms-4" icon={faAngleRight} /></Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                  <Nav.Link eventKey="qa">QA Testing <FontAwesomeIcon className="ms-4" icon={faAngleRight} /></Nav.Link>
                                </Nav.Item>
                              </Nav>
                            </div>

                            <div>
                              <Tab.Content>
                                <Tab.Pane eventKey='wd'>
                                  <h2 className="secsubtitl">Healthcare Management System</h2>
                                  <div className="megamenuflex">
                                    <div className="megamenucont">
                                      <div>
                                        <div className="megamenufeat">
                                          <h2 className="megamenuh2">Web Development</h2>
                                          <p className="whatwedop">From sleek websites to robust web applications, we build digital platforms that engage users and drive results.</p>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="megamenucont">
                                      <div className="megamenufeat">
                                        <h2 className="megamenuh2">Mobile App Development</h2>
                                        <p className="whatwedop">Our mobile app development team crafts intuitive, feature-rich apps for iOS and Android, ensuring seamless user experiences.</p>
                                      </div>
                                    </div>
                                    <div className="megamenucont">
                                      <div className="megamenufeat">
                                        <h2 className="megamenuh2">Visual Graphic Design</h2>
                                        <p className="whatwedop">Our creative graphic designers bring your brand to life with stunning visuals that resonate with your audience.</p>
                                      </div>
                                    </div>
                                    <div className="megamenucont">
                                      <div className="megamenufeat">
                                        <h2 className="megamenuh2">Software Development</h2>
                                        <p className="whatwedop">From sleek websites to robust web applications, we build digital platforms that engage users and drive results.</p>
                                      </div>
                                    </div>
                                    <div className="megamenucont">
                                      <div className="megamenufeat">
                                        <h2 className="megamenuh2">Blockchain Development</h2>
                                        <p className="whatwedop">Revolutionize with blockchain. We develop decentralized applications and systems that enhance security and transparency.</p>
                                      </div>
                                    </div>
                                    <div className="megamenucont">
                                      <div className="megamenufeat">
                                        <h2 className="megamenuh2">Software Testing</h2>
                                        <p className="whatwedop">Quality assurance for flawless performance. Our rigorous testing processes ensure that your software is reliable, secure, and bug-free.</p>
                                      </div>
                                    </div>
                                  </div>
                                  <Link to='/contact-us' className="btn sitebtn mt-2">
                                    Talk to our expert
                                  </Link>
                                </Tab.Pane>
                                <Tab.Pane eventKey='md'>
                                  <h2 className="secsubtitl">Staff Management System</h2>
                                  <div className="megamenuflex">
                                    <div className="megamenucont">
                                      <div>
                                        <div className="megamenufeat">
                                          <h2 className="megamenuh2">Web Development</h2>
                                          <p className="whatwedop">From sleek websites to robust web applications, we build digital platforms that engage users and drive results.</p>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="megamenucont">
                                      <div className="megamenufeat">
                                        <h2 className="megamenuh2">Mobile App Development</h2>
                                        <p className="whatwedop">Our mobile app development team crafts intuitive, feature-rich apps for iOS and Android, ensuring seamless user experiences.</p>
                                      </div>
                                    </div>
                                    <div className="megamenucont">
                                      <div className="megamenufeat">
                                        <h2 className="megamenuh2">Visual Graphic Design</h2>
                                        <p className="whatwedop">Our creative graphic designers bring your brand to life with stunning visuals that resonate with your audience.</p>
                                      </div>
                                    </div>
                                    <div className="megamenucont">
                                      <div className="megamenufeat">
                                        <h2 className="megamenuh2">Software Development</h2>
                                        <p className="whatwedop">From sleek websites to robust web applications, we build digital platforms that engage users and drive results.</p>
                                      </div>
                                    </div>
                                    <div className="megamenucont">
                                      <div className="megamenufeat">
                                        <h2 className="megamenuh2">Blockchain Development</h2>
                                        <p className="whatwedop">Revolutionize with blockchain. We develop decentralized applications and systems that enhance security and transparency.</p>
                                      </div>
                                    </div>
                                    <div className="megamenucont">
                                      <div className="megamenufeat">
                                        <h2 className="megamenuh2">Software Testing</h2>
                                        <p className="whatwedop">Quality assurance for flawless performance. Our rigorous testing processes ensure that your software is reliable, secure, and bug-free.</p>
                                      </div>
                                    </div>
                                  </div>
                                  <Link to='/contact-us' className="btn sitebtn mt-2">
                                    Talk to our expert
                                  </Link>
                                </Tab.Pane>
                                <Tab.Pane eventKey='bd'>
                                  <h2 className="secsubtitl">Project Management System</h2>
                                  <div className="megamenuflex">
                                    <div className="megamenucont">
                                      <div>
                                        <div className="megamenufeat">
                                          <h2 className="megamenuh2">Web Development</h2>
                                          <p className="whatwedop">From sleek websites to robust web applications, we build digital platforms that engage users and drive results.</p>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="megamenucont">
                                      <div className="megamenufeat">
                                        <h2 className="megamenuh2">Mobile App Development</h2>
                                        <p className="whatwedop">Our mobile app development team crafts intuitive, feature-rich apps for iOS and Android, ensuring seamless user experiences.</p>
                                      </div>
                                    </div>
                                    <div className="megamenucont">
                                      <div className="megamenufeat">
                                        <h2 className="megamenuh2">Visual Graphic Design</h2>
                                        <p className="whatwedop">Our creative graphic designers bring your brand to life with stunning visuals that resonate with your audience.</p>
                                      </div>
                                    </div>
                                    <div className="megamenucont">
                                      <div className="megamenufeat">
                                        <h2 className="megamenuh2">Software Development</h2>
                                        <p className="whatwedop">From sleek websites to robust web applications, we build digital platforms that engage users and drive results.</p>
                                      </div>
                                    </div>
                                    <div className="megamenucont">
                                      <div className="megamenufeat">
                                        <h2 className="megamenuh2">Blockchain Development</h2>
                                        <p className="whatwedop">Revolutionize with blockchain. We develop decentralized applications and systems that enhance security and transparency.</p>
                                      </div>
                                    </div>
                                    <div className="megamenucont">
                                      <div className="megamenufeat">
                                        <h2 className="megamenuh2">Software Testing</h2>
                                        <p className="whatwedop">Quality assurance for flawless performance. Our rigorous testing processes ensure that your software is reliable, secure, and bug-free.</p>
                                      </div>
                                    </div>
                                  </div>
                                  <Link to='/contact-us' className="btn sitebtn mt-2">
                                    Talk to our expert
                                  </Link>
                                </Tab.Pane>
                              </Tab.Content>
                            </div>
                          </Tab.Container>
                        </div>
                      </div>
                    </div>
                  </Container>
                </div>
              </div>
              <div className="customdropdown">
                <Button className="dropbtn menubtn">Resources
                  <FontAwesomeIcon className="ms-2" icon={faCaretDown} />
                </Button>
                <div className="dropdown-content">
                  <Container>
                    <div className="dropdownrow row">
                      <div className="dropdowncolumn column">
                        <div className="menuflex">
                          <Tab.Container defaultActiveKey="wd">
                            <div>
                              <Nav variant="pills" className="flex-column">
                                <Nav.Item>
                                  <Nav.Link eventKey="wd">Web Development<FontAwesomeIcon className="ms-4" icon={faAngleRight} /></Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                  <Nav.Link eventKey="md">Mobile Development <FontAwesomeIcon className="ms-4" icon={faAngleRight} /></Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                  <Nav.Link eventKey="bd">Blockchain Development <FontAwesomeIcon className="ms-4" icon={faAngleRight} /></Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                  <Nav.Link eventKey="ad">AI & ML Development <FontAwesomeIcon className="ms-4" icon={faAngleRight} /></Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                  <Nav.Link eventKey="uiux">UI/UX Design <FontAwesomeIcon className="ms-4" icon={faAngleRight} /></Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                  <Nav.Link eventKey="dm">Digital Marketing <FontAwesomeIcon className="ms-4" icon={faAngleRight} /></Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                  <Nav.Link eventKey="br">Branding <FontAwesomeIcon className="ms-4" icon={faAngleRight} /></Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                  <Nav.Link eventKey="qa">QA Testing <FontAwesomeIcon className="ms-4" icon={faAngleRight} /></Nav.Link>
                                </Nav.Item>
                              </Nav>
                            </div>

                            <div>
                              <Tab.Content>
                                <Tab.Pane eventKey='wd'>
                                  <h2 className="secsubtitl">Healthcare Management System</h2>
                                  <div className="megamenuflex">
                                    <div className="megamenucont">
                                      <div>
                                        <div className="megamenufeat">
                                          <h2 className="megamenuh2">Web Development</h2>
                                          <p className="whatwedop">From sleek websites to robust web applications, we build digital platforms that engage users and drive results.</p>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="megamenucont">
                                      <div className="megamenufeat">
                                        <h2 className="megamenuh2">Mobile App Development</h2>
                                        <p className="whatwedop">Our mobile app development team crafts intuitive, feature-rich apps for iOS and Android, ensuring seamless user experiences.</p>
                                      </div>
                                    </div>
                                    <div className="megamenucont">
                                      <div className="megamenufeat">
                                        <h2 className="megamenuh2">Visual Graphic Design</h2>
                                        <p className="whatwedop">Our creative graphic designers bring your brand to life with stunning visuals that resonate with your audience.</p>
                                      </div>
                                    </div>
                                    <div className="megamenucont">
                                      <div className="megamenufeat">
                                        <h2 className="megamenuh2">Software Development</h2>
                                        <p className="whatwedop">From sleek websites to robust web applications, we build digital platforms that engage users and drive results.</p>
                                      </div>
                                    </div>
                                    <div className="megamenucont">
                                      <div className="megamenufeat">
                                        <h2 className="megamenuh2">Blockchain Development</h2>
                                        <p className="whatwedop">Revolutionize with blockchain. We develop decentralized applications and systems that enhance security and transparency.</p>
                                      </div>
                                    </div>
                                    <div className="megamenucont">
                                      <div className="megamenufeat">
                                        <h2 className="megamenuh2">Software Testing</h2>
                                        <p className="whatwedop">Quality assurance for flawless performance. Our rigorous testing processes ensure that your software is reliable, secure, and bug-free.</p>
                                      </div>
                                    </div>
                                  </div>
                                  <Link to='/contact-us' className="btn sitebtn mt-2">
                                    Talk to our expert
                                  </Link>
                                </Tab.Pane>
                                <Tab.Pane eventKey='md'>
                                  <h2 className="secsubtitl">Staff Management System</h2>
                                  <div className="megamenuflex">
                                    <div className="megamenucont">
                                      <div>
                                        <div className="megamenufeat">
                                          <h2 className="megamenuh2">Web Development</h2>
                                          <p className="whatwedop">From sleek websites to robust web applications, we build digital platforms that engage users and drive results.</p>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="megamenucont">
                                      <div className="megamenufeat">
                                        <h2 className="megamenuh2">Mobile App Development</h2>
                                        <p className="whatwedop">Our mobile app development team crafts intuitive, feature-rich apps for iOS and Android, ensuring seamless user experiences.</p>
                                      </div>
                                    </div>
                                    <div className="megamenucont">
                                      <div className="megamenufeat">
                                        <h2 className="megamenuh2">Visual Graphic Design</h2>
                                        <p className="whatwedop">Our creative graphic designers bring your brand to life with stunning visuals that resonate with your audience.</p>
                                      </div>
                                    </div>
                                    <div className="megamenucont">
                                      <div className="megamenufeat">
                                        <h2 className="megamenuh2">Software Development</h2>
                                        <p className="whatwedop">From sleek websites to robust web applications, we build digital platforms that engage users and drive results.</p>
                                      </div>
                                    </div>
                                    <div className="megamenucont">
                                      <div className="megamenufeat">
                                        <h2 className="megamenuh2">Blockchain Development</h2>
                                        <p className="whatwedop">Revolutionize with blockchain. We develop decentralized applications and systems that enhance security and transparency.</p>
                                      </div>
                                    </div>
                                    <div className="megamenucont">
                                      <div className="megamenufeat">
                                        <h2 className="megamenuh2">Software Testing</h2>
                                        <p className="whatwedop">Quality assurance for flawless performance. Our rigorous testing processes ensure that your software is reliable, secure, and bug-free.</p>
                                      </div>
                                    </div>
                                  </div>
                                  <Link to='/contact-us' className="btn sitebtn mt-2">
                                    Talk to our expert
                                  </Link>
                                </Tab.Pane>
                                <Tab.Pane eventKey='bd'>
                                  <h2 className="secsubtitl">Project Management System</h2>
                                  <div className="megamenuflex">
                                    <div className="megamenucont">
                                      <div>
                                        <div className="megamenufeat">
                                          <h2 className="megamenuh2">Web Development</h2>
                                          <p className="whatwedop">From sleek websites to robust web applications, we build digital platforms that engage users and drive results.</p>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="megamenucont">
                                      <div className="megamenufeat">
                                        <h2 className="megamenuh2">Mobile App Development</h2>
                                        <p className="whatwedop">Our mobile app development team crafts intuitive, feature-rich apps for iOS and Android, ensuring seamless user experiences.</p>
                                      </div>
                                    </div>
                                    <div className="megamenucont">
                                      <div className="megamenufeat">
                                        <h2 className="megamenuh2">Visual Graphic Design</h2>
                                        <p className="whatwedop">Our creative graphic designers bring your brand to life with stunning visuals that resonate with your audience.</p>
                                      </div>
                                    </div>
                                    <div className="megamenucont">
                                      <div className="megamenufeat">
                                        <h2 className="megamenuh2">Software Development</h2>
                                        <p className="whatwedop">From sleek websites to robust web applications, we build digital platforms that engage users and drive results.</p>
                                      </div>
                                    </div>
                                    <div className="megamenucont">
                                      <div className="megamenufeat">
                                        <h2 className="megamenuh2">Blockchain Development</h2>
                                        <p className="whatwedop">Revolutionize with blockchain. We develop decentralized applications and systems that enhance security and transparency.</p>
                                      </div>
                                    </div>
                                    <div className="megamenucont">
                                      <div className="megamenufeat">
                                        <h2 className="megamenuh2">Software Testing</h2>
                                        <p className="whatwedop">Quality assurance for flawless performance. Our rigorous testing processes ensure that your software is reliable, secure, and bug-free.</p>
                                      </div>
                                    </div>
                                  </div>
                                  <Link to='/contact-us' className="btn sitebtn mt-2">
                                    Talk to our expert
                                  </Link>
                                </Tab.Pane>
                              </Tab.Content>
                            </div>
                          </Tab.Container>
                        </div>
                      </div>
                    </div>
                  </Container>
                </div>
              </div>
              <Link to='#'>
                Portfolio
              </Link>
              <Link to='#'>
                Blog
              </Link>
              <Link to='#'>
                About Us
              </Link>
              <Link to="/contact-us" className="contbtnlink headerbtn">
                <Button variant="outline-secondary" id="button-addon2">Contact Us <span className="ms-2 cntbtnbg"><Image src={ArrowRightIcon} /></span>
                </Button>
              </Link>
            </div>

          </div>
        </Container>
      </div> */}
    </>
  );
}

export default HomeHeader;